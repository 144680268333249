import { getConvertDataProduct } from './product';

export const getConvertDataPersonalSelections = (data) => {
  if (!data || !data.length) {
    return [];
  }

  return data.map((selection) => {
    const listRecommendations = selection.recommendations && selection.recommendations.length
      ? selection.recommendations.map((product) => getConvertDataProduct(product))
      : [];

    return {
      selection,
      id              : selection.id,
      product         : selection.product ? getConvertDataProduct(selection.product) : null,
      offers          : selection.offers,
      recommendations : listRecommendations
    };
  });
};
