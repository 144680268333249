import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getDeliveryPoints         : (typeBasket) => `/v2/internal/basket/${typeBasket}/delivery_points/`,
  getListDelivery           : (typeBasket) => `/v1/delivery/${typeBasket}/list/`,
  getSuggestsDeliveryPoints : (typeBasket) => `/v2/internal/basket/${typeBasket}/delivery_points/suggests/`,
  getDetailDeliveryPoint    : (typeBasket, code, id) => `/v2/internal/basket/${typeBasket}/delivery_points/detail/type/${code}/id/${id}/`,
  getDeliveryDatePickup     : '/v1/delivery/date/pickup/',
  getDeliveryDateCourier    : (typeBasket) => `/v2/internal/basket/${typeBasket}/checkout/delivery_date/courier/`,
  addAddressDelivery        :
    (typeBasket, deliveryAddressType) => `/v2/internal/basket/${typeBasket}/checkout/delivery_address/${deliveryAddressType}/save/`,
  setAddressDelivery:
    (typeBasket, deliveryAddressType) => `/v2/internal/basket/${typeBasket}/checkout/delivery_address/${deliveryAddressType}/set/`,
  setDataCustomer        : (typeBasket) => `/v2/internal/basket/${typeBasket}/checkout/recipient/set/`,
  getListAddressDelivery : (typeBasket) => `/v2/internal/basket/${typeBasket}/checkout/delivery_address/`,
  createOrder            : (typeBasket) => `/v2/internal/basket/${typeBasket}/order/`,
  onlinePayment          : (orderId) => `/v1/payment/${orderId}/link/`,
  getPaymentsTypes       : (typeBasket) => `/v1/payment/${typeBasket}/list/`,

  setAgreementOrderPublicOffer : '/v1/agreement/order-public-offer/consent/',
  getAgreementStatus           : (type) => `/v1/agreement/${type}/status/`
};

export default class CheckoutModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDeliveryPoints(typeBasket) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDeliveryPoints(typeBasket)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getListDelivery(typeBasket) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListDelivery(typeBasket)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getDetailDeliveryPoint(typeBasket, code, id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailDeliveryPoint(typeBasket, code, id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getSuggestsDeliveryPoints(typeBasket, params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSuggestsDeliveryPoints(typeBasket), { params }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getDeliveryDatePickup(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getDeliveryDatePickup, params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getDeliveryDateCourier(typeBasket, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getDeliveryDateCourier(typeBasket), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  addAddressDelivery(typeBasket, deliveryAddressType, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addAddressDelivery(typeBasket, deliveryAddressType), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setAddressDelivery(typeBasket, deliveryAddressType, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setAddressDelivery(typeBasket, deliveryAddressType), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getListAddressDelivery(typeBasket, deliveryAddressType, params) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListAddressDelivery(typeBasket), { params }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setDataCustomer(typeBasket, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setDataCustomer(typeBasket), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  createOrder(typeBasket, params, token) {
    let config = {};
    if (token) {
      config = {
        headers: {
          'x-vox-implant-auth-token': token
        }
      };
    }

    return new Promise((resolve, reject) => {
      this.post(urlFactory.createOrder(typeBasket), params, config).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  onlinePayment(orderId) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.onlinePayment(orderId)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getPaymentsTypes(typeBasket) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPaymentsTypes(typeBasket)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setAgreementOrderPublicOffer() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setAgreementOrderPublicOffer).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getAgreementStatus(type) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAgreementStatus(type)).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
